import svgPanZoom from "svg-pan-zoom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";
import { customEventsHandler } from "./map-events";

export default class Three {
  _map;

  _coverBackground = anime({
    targets: '.three__layer--background',
    translateY: '+=100px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverForeground = anime({
    targets: '.three__layer--foreground',
    translateY: '+=50px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addMap() {
    this._map = document.querySelector('#three-map');
    this._map.addEventListener('load', this._handleMapLoad.bind(this));

    return this;
  }

  addScrolls() {
    ScrollTrigger.create({
      trigger: '.three__figure',
      start: 'top center',
      end: 'bottom',
      scroller: '.three .fp-overflow',
      onUpdate: (self) => {
        this._coverBackground.seek(this._coverBackground.duration * self.progress);
        this._coverForeground.seek(this._coverForeground.duration * self.progress);
      }
    });

    return this;
  }

  addGraphs() {
    const barGraph = this._barGraphTimeline();
    const bubbleGraph = this._bubbleGraphTimeline();
    const stackGraph = this._stackGraphTimeline();

    ScrollTrigger.create({
      trigger: '.three-bar',
      start: "top center",
      end: 'bottom',
      scroller: '.three .fp-overflow',
      // once: true,
      onToggle: () => barGraph.play()
    });

    ScrollTrigger.create({
      trigger: '.three-stats',
      start: "top center",
      end: 'bottom',
      scroller: '.three .fp-overflow',
      // once: true,
      onToggle: () => bubbleGraph.play()
    });

    ScrollTrigger.create({
      trigger: '.three-virtuous',
      start: "top center",
      end: 'bottom',
      scroller: '.three .fp-overflow',
      // once: true,
      onToggle: () => stackGraph.play()
    });

    return this;
  }

  _handleMapLoad() {
    const home = document.querySelector('.three-map__control--home');
    const plus = document.querySelector('.three-map__control--plus');
    const minus = document.querySelector('.three-map__control--minus');

    const svg = svgPanZoom(this._map, {
      zoomEnabled: true,
      controlIconsEnabled: false,
      mouseWheelZoomEnabled: false,
      fit: false,
      contain: true,
      center: false,
      customEventsHandler: customEventsHandler
    });

    home.addEventListener('click', () => svg.resetZoom());
    plus.addEventListener('click', () => svg.zoomIn());
    minus.addEventListener('click', () => svg.zoomOut());
  }

  _barGraphTimeline() {
    const timeline = anime.timeline({ autoplay: false, duration: 800, easing: 'easeOutExpo' });

    const first = {
      targets: '.three-bar__bar--1',
      height: ['0px', '340px'],
    };

    const second = {
      targets: '.three-bar__bar--2',
      height: ['0px', '130px']
    };

    const third = {
      targets: '.three-bar__bar--3',
      height: ['0px', '100px']
    };

    timeline.add(first).add(second).add(third);

    return timeline;
  }

  _bubbleGraphTimeline() {
    const timeline = anime.timeline({ autoplay: false, duration: 1800, easing: 'easeOutExpo' });

    const first = {
      targets: '.three-stats__bubble',
      scale: [0, 1]
    };

    timeline.add(first);

    return timeline;
  }

  _stackGraphTimeline() {
    const timeline = anime.timeline({ autoplay: false, duration: 800, easing: 'easeOutExpo' });

    const first = {
      targets: '.three-virtuous__item--1',
      scaleY: [0, 1]
    };

    const second = {
      targets: '.three-virtuous__item--2',
      scaleY: [0, 1]
    };

    const third = {
      targets: '.three-virtuous__item--3',
      scaleY: [0, 1]
    };

    timeline.add(first).add(second).add(third);

    return timeline;
  }
}