import fullpage from 'fullpage.js'
import One from './modules/one';
import Three from "./modules/three";
import Two from "./modules/two";
import Four from "./modules/four";
import Five from "./modules/five";
import Six from "./modules/six";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    new fullpage('.main-content', {
      navigation: true,
      scrollOverflow: true
    });

    new One().addMap().addScrolls().addCounters();
    new Two().addScrolls();
    new Three().addMap().addScrolls().addGraphs();
    new Four().addGraphs().addCounters();
    new Five().addScrolls().addCounters();
    new Six().addGraphs().addCounters();
  }

})();
