import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";

export default class Two {

  _coverBackground = anime({
    targets: '.two__layer--background',
    translateY: '+=200px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverForeground = anime({
    targets: '.two__layer--foreground',
    translateY: '+=100px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });
  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addScrolls() {
    const blocks = document.querySelectorAll('.two-values__block');

    ScrollTrigger.create({
      trigger: '.two__figure',
      start: 'top center',
      end: 'bottom',
      scroller: '.two .fp-overflow',
      onUpdate: (self) => {
        this._coverBackground.seek(this._coverBackground.duration * self.progress);
        this._coverForeground.seek(this._coverForeground.duration * self.progress);
      }
    });

    blocks.forEach((el, i) => {
      ScrollTrigger.create({
        trigger: el,
        start: 'top bottom',
        end: 'bottom',
        scroller: '.two .fp-overflow',
        once: true,
        onToggle: () => {
          anime({
            targets: el,
            opacity: [0, 1],
            duration: 1000,
            delay: i * 500,
            easing: 'linear'
          });
        }
      });
    });

    return this;
  }
}