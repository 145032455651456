import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";

export default class Four {
  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addGraphs() {
    const bubbleGraph = this._bubbleGraphTimeline();
    const gridGraph = this._gridGraphTimeline();

    ScrollTrigger.create({
      trigger: '.four-goals',
      start: "top center",
      end: 'bottom',
      scroller: '.four .fp-overflow',
      once: true,
      onToggle: (self) => bubbleGraph.play()
    });

    ScrollTrigger.create({
      trigger: '.four-sewers',
      start: "top center",
      end: 'bottom',
      scroller: '.four .fp-overflow',
      once: true,
      onToggle: (self) => gridGraph.play()
    });

    return this;
  }

  addCounters() {
    const elements = document.querySelectorAll('.four-focus__counter');
    const single = document.querySelector('.four-crisis__value');

    elements.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top bottom",
        scroller: '.four .fp-overflow',
        once: true,
        onToggle: (self) => {
          const endValue = el.dataset.counterValue;
          const suffix = el.dataset.counterSuffix;

          let item = { value: `0${suffix}` };

          if (self.isActive) {
            anime({
              targets: item,
              value: `${endValue}${suffix}`,
              round: 1,
              easing: 'linear',
              duration: 2000,
              update: () => el.innerText = item.value
            })
          }
        }
      });
    });

    ScrollTrigger.create({
      trigger: '.four-crisis--box',
      start: 'top bottom',
      scroller: '.four .fp-overflow',
      once: true,
      onToggle: (self) => {
        const endValue = single.dataset.counterValue;
        const suffix = single.dataset.counterSuffix;

        let item = { value: `0${suffix}` };

        if (self.isActive) {
          anime({
            targets: item,
            value: `${endValue}${suffix}`,
            round: 1,
            easing: 'linear',
            duration: 2000,
            update: () => single.innerText = item.value
          })
        }
      }
    });

    return this;
  }

  _bubbleGraphTimeline() {
    const timeline = anime.timeline({ autoplay: false, duration: 800, easing: 'easeOutExpo' });

    const first = {
      targets: '.four-goals__bubble--1',
      scale: [0, 1]
    };

    const second = {
      targets: '.four-goals__bubble--2',
      scale: [0, 1]
    };

    const third = {
      targets: '.four-goals__bubble--3',
      scale: [0, 1]
    };

    timeline.add(third).add(second).add(first);

    return timeline;
  }

  _gridGraphTimeline() {
    const items = document.querySelectorAll('.four-sewers__man');
    const timeline = anime.timeline({ autoplay: false, duration: 40, easing: 'easeOutExpo' });

    items.forEach((el) => {
      const element = {
        targets: el,
        opacity: [0, 1]
      };

      timeline.add(element);
    })

    return timeline;
  }

}