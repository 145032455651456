import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";

export default class Five {

  _coverBackground = anime({
    targets: '.five__layer--background',
    translateY: '+=200px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverForeground = anime({
    targets: '.five__layer--foreground',
    translateY: '+=100px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverCloseup = anime({
    targets: '.five__layer--closeup',
    translateY: '+=50px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addScrolls() {
    ScrollTrigger.create({
      trigger: '.five__figure',
      start: 'top center',
      end: 'bottom',
      scroller: '.five .fp-overflow',
      onUpdate: (self) => {
        this._coverBackground.seek(this._coverBackground.duration * self.progress);
        this._coverForeground.seek(this._coverForeground.duration * self.progress);
        this._coverCloseup.seek(this._coverCloseup.duration * self.progress);
      }
    });

    return this;
  }

  addCounters() {
    const renewablesCounter = document.querySelectorAll('.five-renewables__value');
    const focusCounter = document.querySelectorAll('.five-focus__value');

    renewablesCounter.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top bottom",
        scroller: '.five .fp-overflow',
        once: true,
        onToggle: (self) => {
          const endValue = el.dataset.counterValue;
          const suffix = el.dataset.counterSuffix;

          let item = { value: `0 ${suffix}` };

          if (self.isActive) {
            anime({
              targets: item,
              value: `${endValue} ${suffix}`,
              round: 1,
              easing: 'linear',
              duration: 2000,
              update: () => el.innerText = item.value
            })
          }
        }
      });
    });

    focusCounter.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "bottom bottom",
        scroller: '.five .fp-overflow',
        once: true,
        onToggle: (self) => {
          const endValue = el.dataset.counterValue;
          const suffix = el.dataset.counterSuffix;

          let item = { value: `0 ${suffix}` };

          if (self.isActive) {
            anime({
              targets: item,
              value: `${endValue} ${suffix}`,
              round: 1,
              easing: 'linear',
              duration: 2000,
              update: () => el.innerText = item.value
            });
          }
        }
      });
    });

    return this;
  }

}