import svgPanZoom from "svg-pan-zoom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";
import { customEventsHandler } from "./map-events";

export default class One {
  _map;

  _rounds = anime({
    targets: '.one-about__svg',
    rotate: '+=1turn',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverBackground = anime({
    targets: '.one__layer--background',
    translateY: '+=200px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  _coverForeground = anime({
    targets: '.one__layer--foreground',
    translateY: '+=100px',
    duration: 1000,
    easing: 'linear',
    autoplay: false
  });

  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addMap() {
    this._map = document.querySelector('#one-map');
    this._map.addEventListener('load', this._handleMapLoad.bind(this));

    return this;
  }

  addScrolls() {
    ScrollTrigger.create({
      trigger: '.one-about',
      start: "top bottom",
      end: 'bottom',
      scroller: '.one .fp-overflow',
      onUpdate: (self) => {
        this._rounds.seek(this._rounds.duration * self.progress);
      }
    });

    ScrollTrigger.create({
      trigger: '.one__figure',
      start: 'top center',
      end: 'bottom',
      scroller: '.one .fp-overflow',
      onUpdate: (self) => {
        this._coverBackground.seek(this._coverBackground.duration * self.progress);
        this._coverForeground.seek(this._coverForeground.duration * self.progress);
      }
    });

    return this;
  }

  addCounters() {
    const elements = document.querySelectorAll('.one-counter__value');

    elements.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top bottom",
        scroller: '.one .fp-overflow',
        once: true,
        onToggle: (self) => {
          const endValue = el.dataset.counterValue;
          const suffix = el.dataset.counterSuffix;

          let item = { value: `0${suffix}` };

          if (self.isActive) {
            anime({
              targets: item,
              value: `${endValue}${suffix}`,
              round: 1,
              easing: 'linear',
              duration: 2000,
              update: () => el.innerText = item.value
            })
          }
        }
      });
    });

    return this;
  }

  _handleMapLoad() {
    const home = document.querySelector('.one-map__control--home');
    const plus = document.querySelector('.one-map__control--plus');
    const minus = document.querySelector('.one-map__control--minus');

    const svg = svgPanZoom(this._map, {
      zoomEnabled: true,
      controlIconsEnabled: false,
      mouseWheelZoomEnabled: false,
      fit: false,
      contain: true,
      minZoom: 1,
      center: false,
      customEventsHandler: customEventsHandler
    });

    home.addEventListener('click', () => svg.resetZoom());
    plus.addEventListener('click', () => svg.zoomIn());
    minus.addEventListener('click', () => svg.zoomOut());
  }
}