import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from "animejs";

export default class Six {
  constructor() {
    gsap.registerPlugin(ScrollTrigger);
  }

  addGraphs() {
    const routesGraph = this._routesGraphsTimeline();

    ScrollTrigger.create({
      trigger: '.six-graph',
      start: "top center",
      scroller: '.six .fp-overflow',
      once: true,
      onToggle: (self) => routesGraph.play()
    });

    return this;
  }

  addCounters() {
    const elements = document.querySelectorAll('.six-graph__title');
    const single = document.querySelector('.six-bottles__value');

    elements.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top bottom",
        scroller: '.six .fp-overflow',
        once: true,
        onToggle: (self) => {
          const endValue = el.dataset.counterValue;
          const suffix = el.dataset.counterSuffix;

          let item = { value: `0 ${suffix}` };

          if (self.isActive) {
            anime({
              targets: item,
              value: `${endValue} ${suffix}`,
              round: 1,
              easing: 'linear',
              duration: 2000,
              update: () => el.innerText = item.value
            })
          }
        }
      });
    });

    ScrollTrigger.create({
      trigger: '.six-bottles',
      start: "top bottom",
      scroller: '.six .fp-overflow',
      once: true,
      onToggle: (self) => {
        const endValue = single.dataset.counterValue;

        let item = { value: '0' };

        if (self.isActive) {
          anime({
            targets: item,
            value: `${endValue}`,
            round: 1,
            easing: 'linear',
            duration: 2000,
            update: () => single.innerText = item.value
          })
        }
      }
    });

    return this;
  }

  _routesGraphsTimeline() {
    const routes = document.querySelectorAll('.six-graph__item');
    const timeline = anime.timeline({ autoplay: false, duration: 500, easing: 'easeOutExpo' });

    routes.forEach((el, i) => {
      const element = {
        targets: el,
        fill: ['#fff', el.getAttribute('fill')]
      };

      timeline.add(element, i === 0 ? 0 : '-=250');
    })

    return timeline;
  }

}